import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vim stands for vi improved and is a command-line text editor installed with every platform other than windows (unless you `}<a parentName="p" {...{
        "href": "https://www.vim.org/download.php"
      }}>{`install it`}</a>{`. It can be hard to figure out how to do things in this editor. This article aims to give you the basic knowledge of Vim and how to do something with it.`}</p>
    <p>{`Let's start with the basics. How do you open Vim?`}</p>
    <p>{`If you are running Linux or MacOS is most likely that you have vim installed already so you can just open your terminal and run the command `}<inlineCode parentName="p">{`vim <filename/path of file>`}</inlineCode>{` to edit that file. `}</p>
    <p>{`Vi usually comes with linux distributions. If you are on windows you could download it from `}<a parentName="p" {...{
        "href": "https://www.vim.org/download.php"
      }}>{`vim.org`}</a>{`.`}</p>
    <p>{`Don't forget to check the `}<a parentName="p" {...{
        "href": "../cheatcheets/vim"
      }}>{`vim cheatsheet`}</a>{` so you can check the commands that this article talks about. You can also download an image with all these commands.`}</p>
    <h1 {...{
      "id": "vim-modes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vim-modes",
        "aria-label": "vim modes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vim Modes`}</h1>
    <p>{`Vim comes with three different modes:`}</p>
    <ul>
      <li parentName="ul">{`Normal Mode (Default mode)`}</li>
      <li parentName="ul">{`Insert Mode (Press `}<em parentName="li">{`i`}</em>{` key)`}</li>
      <li parentName="ul">{`Visual Mode (Press `}<em parentName="li">{`v`}</em>{` key)`}</li>
    </ul>
    <p>{`These modes are straightforward to understand. You can see which mode you are in on the bottom left corner of the terminal window.`}</p>
    <p>{`The normal mode is the default mode, it's activated as soon as you open Vim. You can move around, read the text, copy, insert lines, etc. The only thing you can't do in this mode is edit the text.`}</p>
    <h2 {...{
      "id": "editing-text",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#editing-text",
        "aria-label": "editing text permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Editing text`}</h2>
    <p>{`If you want to edit the text, you can press the `}<em parentName="p">{`i`}</em>{` key on your keyboard. You can notice that the bottom left corner of the window now shows the text: `}<inlineCode parentName="p">{`--INSERT--`}</inlineCode>{`.`}</p>
    <p>{`You will now be able to use your terminal as a text editor. You can add, edit and delete text as you would do in any other text editor.`}</p>
    <p>{`Once you are happy with the changes you have made, press the `}<em parentName="p">{`esc`}</em>{` key on your keyboard and return to the Normal mode.`}</p>
    <h2 {...{
      "id": "visual-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#visual-mode",
        "aria-label": "visual mode permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Visual Mode`}</h2>
    <p>{`There is nothing much to say about this mode. It allows you to select big chunks of text for you to copy or cut. When this mode is active, you can read the text: `}<inlineCode parentName="p">{`--VISUAL--`}</inlineCode>{` in the bottom left corner of the window.`}</p>
    <p>{`Another thing you can do in the visual mode is to highlight text and then make small changes to the highlighted text, such as changing to uppercase or indenting lines.`}</p>
    <h1 {...{
      "id": "vim-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vim-commands",
        "aria-label": "vim commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vim Commands`}</h1>
    <p>{`Vim is meant to help you do things reasonably quickly without the need for a mouse. Everything can be done with a keyboard, so learning some of the Vim commands will be helpful.`}</p>
    <p>{`Things that you will learn:`}</p>
    <ul>
      <li parentName="ul">{`how to save a file`}</li>
      <li parentName="ul">{`how to quit Vim`}</li>
      <li parentName="ul">{`how to move around`}</li>
      <li parentName="ul">{`how to see line numbers`}</li>
    </ul>
    <h2 {...{
      "id": "saving-and-quitting-vim",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#saving-and-quitting-vim",
        "aria-label": "saving and quitting vim permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Saving and quitting Vim`}</h2>
    <p>{`Now that you know how to edit a text in Vim, the most important thing you will learn will be how to save your changes and quit. If you are in normal mode, then by pressing the `}<em parentName="p">{`:`}</em>{` key of your keyboard, you will be able to enter commands to Vim.`}</p>
    <p>{`All you need to do is type `}<inlineCode parentName="p">{`:w`}</inlineCode>{` and then press the enter key to save a file.`}</p>
    <p>{`To exit vim and go back to the command line, you must use `}<inlineCode parentName="p">{`:q`}</inlineCode>{`.`}</p>
    <p>{`Note that if you made changes to the file and didn't save them. Vim won't automatically exit. Instead, it will tell you to run the command `}<inlineCode parentName="p">{`:q!`}</inlineCode>{`, which translates to force quit.`}</p>
    <p>{`These two commands can be combined into one `}<inlineCode parentName="p">{`:wq`}</inlineCode>{`. This will write the changes to the file and then quit Vim. If you want to be quicker, the key combination `}<inlineCode parentName="p">{`shift+zz`}</inlineCode>{` will achieve the same results.`}</p>
    <p>{`You can also use the command `}<inlineCode parentName="p">{`:w <filename>`}</inlineCode>{` to save a new file with that name - this command is helpful if you didn't open a file with Vim.`}</p>
    <h2 {...{
      "id": "line-numbers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#line-numbers",
        "aria-label": "line numbers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Line numbers`}</h2>
    <p>{`Showing line numbers can be useful when editing a file. Vim allows you to jump straight into a line if you know its number, so your editing can be done quicker if you know exactly where and what to edit.`}</p>
    <p>{`You need to run the command `}<inlineCode parentName="p">{`:set number`}</inlineCode>{` to show line numbers. Once you press enter, you can see that Vim will show the number of each line.`}</p>
    <p>{`If you want to jump straight to a line, you can type the command `}<inlineCode parentName="p">{`:<line number>`}</inlineCode>{`, and the cursor will jump to the beginning of that line.`}</p>
    <h2 {...{
      "id": "moving-around",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#moving-around",
        "aria-label": "moving around permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Moving around`}</h2>
    <p>{`To move around, all you need is to press a few keys to do different things. Moving around doesn't require entering the command mode by pressing the `}<inlineCode parentName="p">{`:`}</inlineCode>{` key.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`h`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow left`}</inlineCode>{` - move the cursor one character to the left`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`l`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow right`}</inlineCode>{` - move the cursor one character to the right`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`j`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow down`}</inlineCode>{` - move the cursor one line down`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`k`}</inlineCode>{` or `}<inlineCode parentName="li">{`arrow up`}</inlineCode>{` - move the cursor one line up`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`0`}</inlineCode>{` - move the cursor to the beginning of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`$`}</inlineCode>{` - move the cursor to the end of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`w`}</inlineCode>{` - move the cursor one word forward`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`b`}</inlineCode>{` - move the cursor one word back`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`gg`}</inlineCode>{` - move to the beginning of the file (line 1)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`G`}</inlineCode>{` - move to the end of the line (last line)`}</li>
    </ul>
    <p>{`These two commands can be used while moving/editing the file:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`o`}</inlineCode>{` - adds an empty line below the cursor, moves the cursor to that line, enters edit mode`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`O`}</inlineCode>{` - adds an empty line above the cursor, moves the cursor to that line, enters edit mode`}</li>
    </ul>
    <h2 {...{
      "id": "deleting-things",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deleting-things",
        "aria-label": "deleting things permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deleting things`}</h2>
    <p>{`You can delete things by pressing the `}<inlineCode parentName="p">{`d`}</inlineCode>{` key. This will serve as the `}<em parentName="p">{`cut`}</em>{` command as well. Vim allows you to combine commands to achieve a purpose, so you can combine the moving around commands with the delete to improve your editing skills.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`de`}</inlineCode>{` - delete from where the cursor is until the end of the current word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dw`}</inlineCode>{` - delete to next word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`d2w`}</inlineCode>{` - delete two words from cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`d$`}</inlineCode>{` or `}<inlineCode parentName="li">{`D`}</inlineCode>{` - delete to the end of the line from where the cursor is`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dd`}</inlineCode>{` - delete the whole line`}</li>
    </ul>
    <h2 {...{
      "id": "other-useful-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-useful-commands",
        "aria-label": "other useful commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other useful commands`}</h2>
    <p>{`Since we tend to delete the wrong things, Vim also comes with an undo and redo command that can come in handy in many situations.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`u`}</inlineCode>{` - undo previous command/action`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL-u`}</inlineCode>{` - redo previous command/action`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`.`}</inlineCode>{` - repeats last command that modified something (such as 2dd - delete two lines)`}</li>
    </ul>
    <p>{`You can also make vim search for a term in the whole file with the commands:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`:?<term>`}</inlineCode>{` - searches for that term from the cursor down`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`:\\<term>`}</inlineCode>{` - searches for that term from the cursor up`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`n`}</inlineCode>{` or `}<inlineCode parentName="li">{`/`}</inlineCode>{` - go to the next searched term`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`N`}</inlineCode>{` or `}<inlineCode parentName="li">{`?`}</inlineCode>{` - go to the previous searched term`}</li>
    </ul>
    <h2 {...{
      "id": "conclusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#conclusion",
        "aria-label": "conclusion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conclusion`}</h2>
    <p>{`This concludes the introduction to Vim. Hopefully, you found it helpful. You still need to learn many things, but this should give you the basics to work around in this text editor.`}</p>
    <p>{`If you would like to know more about Vim, you can run the Vim tutor by running this command on your terminal:`}</p>
    <p><inlineCode parentName="p">{`$ Vimtutor`}</inlineCode></p>
    <p>{`This will open a text file with step-by-step instructions that cover all the basic commands in Vim.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      